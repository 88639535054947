@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

#app {
    min-height: 770px;
}

#footer {
    display: inline-block;
    width: 100%;
    line-height: 24px;
    color: white;
    background-color: #262B2D;
    font-size: 14px;
    font-family: 'Roboto',sans-serif;
    font-weight: 300;
    text-align: center;
    padding: 15px 0 15px 0;
    margin-bottom: -5px;
}

#footer>a {
    color: #989898;
    text-decoration: none;
    padding: 5px;
    margin: 0 10px;
}

a#twitter, a#facebook {
    width: 23px;
    height: 23px;
    padding: 0!important;
    display: inline-block;
    vertical-align: middle;
    margin: 8px 5px 0 !important;
}

#facebook {
    background-image: url(/assets/images/fb-tw.svg);
    background-position: -0px -0px;
}

#twitter {
    background-image: url(/assets/images/fb-tw.svg);
    background-position: -23px -0px;
}

.home-container {
    background-image: url(/assets/images/bg-1.jpg);
    background-size: cover;
    filter: alpha(opacity=60);
    height: 100%;
    min-height: 770px;
    margin-top: -20px;
}

.home-header {
    display: inline-block;
    margin-top: 120px;
    width: 100%;
}

.user-header-view {
    display: inline-block;
    margin: 120px 0 50px 0;
    width: 100%;
}

.home-container h1 {
    color: #fff;
    text-align: center;
    font-family: 'Roboto',sans-serif;
    font-weight: 700;
    font-size: 58px;
    text-transform: uppercase;
    line-height: 66px;
}

.home-container h2 {
    color: #fff;
    text-align: center;
    font-family: 'Roboto',sans-serif;
    font-weight: 300;
    font-size: 30px;
    line-height: 38px;
    text-transform: uppercase;
}

.home-container h3 {
    color: #fff;
    text-align: center;
    font-family: 'Roboto',sans-serif;
    font-weight: 200;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
}

.google-play {
    margin-top: 50px;
}

.user-header-view .google-play{
    margin-top: 0px;
}

.google-play > a {
    width: 230px;
    margin: 0 auto;
    display: block;
    height: 62px;
    opacity: 0.7;
    background-image: url(/assets/images/google-play-badge.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

#user-download-invitation {
    background-color: rgba(181, 174, 174, 0.79);
    min-width: 300px;
    width: 22%;
    display: block;
    padding: 60px 30px 60px 30px;
    margin: auto;
    border-radius: 10%;
}

#user-download-invitation h3 strong {
    text-transform: none;
}

#user-img {
    border-radius: 50%;
    border: 2px solid #fff;
    display: block;
    margin: auto;
}
